import isAndroid from 'is-android'
import isIos from 'is-ios'
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import './styles.css'

export const SocialButton = ({
  facebook = false,
  instagram = false,
  whatsapp = false,
}) => {
  const renderFacebook = () => {
    const link = 'https://www.facebook.com/anaceliawinery' /* isIos
      ? 'fb://profile/anaceliawinery'
      : isAndroid
      ? 'fb://page/anaceliawinery'
      : 'https://www.facebook.com/anaceliawinery' */
    return (
      <a href={link} target='_blank' rel='noreferrer' className='btn'>
        <FaFacebook />
      </a>
    )
  }
  const renderInstagram = () => {
    const link = 'https://www.instagram.com/anacelia_winery/'
    /*   isAndroid || isIos
        ? 'instagram://media?id=anacelia_winery'
        : 'https://www.instagram.com/anacelia_winery/' */
    return (
      <a href={link} target='_blank' rel='noreferrer' className='btn'>
        <FaInstagram />
      </a>
    )
  }

  const renderWhatsapp = () => (
    <a href='https://wa.me/56982001584/?text=Hola' className='btn'>
      <FaWhatsapp />
    </a>
  )

  console.log('isAndroid', isAndroid)
  console.log('isIos', isIos)

  return facebook ? (
    renderFacebook()
  ) : instagram ? (
    renderInstagram()
  ) : whatsapp ? (
    renderWhatsapp()
  ) : (
    <></>
  )
}
