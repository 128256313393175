import logo from './ac_winery_logo.png'
import { SocialButton } from './components/SocialButton'
import './App.css'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='logo' alt='logo' />
      </header>
      <div className='content'>
        <h2>
          Síguenos en nuestras Redes sociales y entérate de nuestras novedades
        </h2>
        <SocialButton facebook />
        <SocialButton instagram />
        <SocialButton whatsapp />
      </div>
    </div>
  )
}

export default App
